<template>
    <div>
        <div style="background-color: white">
            <v-select v-if="stabilimento && stabilimento.reparti && stabilimento.reparti.length"
                      :items="[{_id: '', nome:'Tutti'}].concat(stabilimento.reparti)"
                      v-model="filtroReparto"
                      item-text="nome"
                      item-value="_id"
                      label="Seleziona un reparto"
                      @change="initMap" />
        </div>
        <div class="mappaEvacuatori" style="height:100%;">
            <v-layout justify-center align-center style="height:100%;">
                <canvas ref="mappa" style="width:100%;"></canvas>
            </v-layout>
            <v-container grid-list-sm fluid class="mt-3" style="background-color: white">
                <v-row>
                    <v-col cols="12" md="4" offset-md="4" sm="12" class="pt-0">
                        <v-btn color="primary" width="100%" @click="emitAndClose">Fatto</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
import apiStabilimenti from '@/js/pages/stabilimenti';
const Schede = () => import('@/components/Schede');
const ListaEvacuatori = () => import('@/components/Lista_evacuatori');
let context;

export default {
    name: 'mappaEvacuatori',
    components: {
        Schede,
        ListaEvacuatori
    },
    props: [
        'id_stabilimento',
        'isChosing',
        'list_selected',
        'multipleChosing'
    ],
    data() {
        return {
            token: this.$cookies.get('token'),
            loading: false,
            punti: [],
            lista_evacuatori: [],
            viewPointMenu: false,
            positionXpointMenu: 0,
            positionYpointMenu: 0,
            pointClicked: undefined,
            viewEvacuatore: undefined,
            isChosingEvacuatore: false,
            point_selezionati: [],
            evacuatori_selezionati: [],
            stabilimento: undefined,
            filtroReparto: undefined
        }
    },
    async created() {
        if(
            this.list_selected &&
            this.list_selected.length
        ) this.evacuatori_selezionati = [...this.list_selected];
    },
    async mounted() {
        await this.initMap();
    },
    methods: {
        async initMap() {
            const stabilimento = await apiStabilimenti.getOne(this.id_stabilimento);
            this.punti = stabilimento.punti_mappa || [];
            if(stabilimento.file) {
                let canvas = this.$refs.mappa;
                if(!canvas) {
                    await this.initMap();
                    return;
                }
                let px_contHeight = canvas.clientHeight;
                let px_contWidth = canvas.clientWidth;
                canvas.height = px_contHeight;
                canvas.width = px_contWidth;
                context = undefined;
                context = canvas.getContext('2d');
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.imageSmoothingEnabled = false;
                context.webkitImageSmoothingEnabled = false;
                context.mozImageSmoothingEnabled = false;

                let those = this;

                let base_image = new Image();
                base_image.src = process.env.VUE_APP_ENDPOINT_API+stabilimento.file;
                base_image.onload = function() {
                    context.drawImage(base_image, 0, 0, base_image.width, base_image.height, 0, 0, px_contWidth, px_contHeight);

                    for (const punto of those.punti) {
                        let valid = true;
                        if (
                            those.filtroReparto &&
                            String(punto.id_reparto) !== String(those.filtroReparto)
                        ) valid = false;

                        if (valid) {
                            const posXcalc = ((px_contWidth * punto.x) / 100);
                            const posYcalc = ((px_contHeight * punto.y) / 100);
                            context.beginPath();
                            context.arc(posXcalc, posYcalc, 3, 0, 2 * Math.PI, false);
                            context.lineWidth = 3;
                            context.strokeStyle = punto.colorePunto || '#00ff00';
                            context.stroke();
                            if (
                                punto.id_evacuatore &&
                                those.evacuatori_selezionati.indexOf(punto.id_evacuatore) !== -1
                            ) {
                                context.beginPath();
                                context.arc(posXcalc, posYcalc, 10, 0, 2 * Math.PI, false);
                                context.lineWidth = 4;
                                context.strokeStyle = 'red';
                                context.stroke();
                            }
                        }
                    }
                }

                canvas.addEventListener('mousedown', async function(e) {
                    if(
                        !those.loading &&
                        those.isChosing
                    ){
                        those.loading = true;
                        let objPosition = those.getCursorPosition(canvas, e);
                        const posXcalc = ((objPosition.x * 100) / px_contWidth);
                        const posYcalc = ((objPosition.y * 100) / px_contHeight);
                        let found = await those.isOnPunto(posXcalc,posYcalc);
                        if(found) await those.initMap();
                        those.loading = false;
                    }
                });
            } else this.$refs.mappa.height = 0;

            this.stabilimento = stabilimento;
        },
        getCursorPosition(canvas, event) {
            const rect = canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            return {
                x: x,
                y: y
            };
        },
        isOnPunto(x, y) {
            let puntoFound = false;
            for (let punto of this.punti) {
                let calcX = parseInt(punto.x) - parseInt(x);
                if(calcX < 0) calcX = calcX * -1;
                let calcY = parseInt(punto.y) - parseInt(y);
                if(calcY < 0) calcY = calcY * -1;
                if(
                    calcX < 1.2 &&
                    calcY < 1.2
                ) {
                    if(punto.id_evacuatore) {
                        let posEvacuatore = this.evacuatori_selezionati.indexOf(punto.id_evacuatore);
                        if(
                            posEvacuatore === -1 &&
                            (
                                this.evacuatori_selezionati.length === 0 ||
                                this.multipleChosing
                            )
                        ) this.evacuatori_selezionati.push(punto.id_evacuatore);
                        else this.evacuatori_selezionati.splice(posEvacuatore, 1);
                        puntoFound = punto;
                    }
                }
            }
            return puntoFound;
        },
        emitAndClose() {
            if(this.multipleChosing) this.$emit('evacuatoriSelected', this.evacuatori_selezionati);
            else {
                let objEvacuatore = this.evacuatori_selezionati[0] ? { _id: this.evacuatori_selezionati[0] } : undefined;
                this.$emit('evacuatoreClick', objEvacuatore);
            }
        }
    }
}
</script>